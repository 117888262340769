<template>
  <div class="container">
    <div class="header">
      <van-image class="header-avatar" :src="notice_sender.sender_avatar" />
      <div class="header-name">{{ notice_sender.sender_name }}</div>
    </div>
    <div class="article">
      <div class="article-h2">{{ detail.notice_title }}</div>
      <div class="article-time">{{ detail.notice_at }}</div>
      <div class="article-content" v-html="detail.notice_content" />
    </div>
    <loadings :loading='searchLoading' />
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'
import Loadings from '@/components/loading'

export default {
  name: 'NoticeDetail',
  mixins: [PageMixin],
  data() {
    return {
      detail: '',
      notice_sender:{},
      searchLoading:false
    }
  },
  components: {
    loadings:Loadings,
  },
  created() {
    this.onDetail()
  },
  methods: {
    onDetail() {
      this.searchLoading = true
      this.$api.notice_detail({ notice_id: this.$route.query.notice_id }).then(res => {
        this.detail = res.data
        this.notice_sender = res.data.notice_sender
        this.searchLoading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>

  .header{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    &-avatar{
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 10px;
    }
    &-name{
      font-size: 14px;
      color: #666;
    }
  }

  .article{
    background: #fff;
    padding: 10px 15px;
    &-h2{
      color: #333;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 16px;
    }
    &-time{
      color: #999;
      font-size: 12px;
      margin-bottom: 10px;
    }
    &-content{
      font-size: 14px;
    }
  }
</style>

